import insta from 'common/src/assets/image/app/instagram/logo.svg';
import trans from 'common/src/assets/image/app/translations/logo.svg';
import follow from 'common/src/assets/image/app/followers/logo.svg';
import who from 'common/src/assets/image/agency/who/logo.svg';
import scanner from 'common/src/assets/image/agency/scanner/logo.svg';
import matdev from 'common/src/assets/image/agency/matdev/logo.svg';
import scannerapp from 'common/src/assets/image/app/scannerapp/logo.svg';
import videoup from 'common/src/assets/image/agency/videoup/logo.svg';
import collage from 'common/src/assets/image/app/collage/logo.svg';
import qr from 'common/src/assets/image/agency/qr/logo.svg';
import developerfaxer from 'common/src/assets/image/app/developerfaxer/logo.svg';
import cleaner from 'common/src/assets/image/app/cleaner/logo.svg';
import royalmobileapps from 'common/src/assets/image/agency/royalmobileapps/logo.svg';
import ereasy from 'common/src/assets/image/agency/ereasy/logo.svg';
import photoup from 'common/src/assets/image/agency/photoup/logo.svg';
import imote from 'common/src/assets/image/agency/imote/logo.svg';
import spamsafe from 'common/src/assets/image/agency/spamsafe/logo.svg';
import ai from 'common/src/assets/image/agency/ai/logo.svg';
import typi from 'common/src/assets/image/agency/typi/logo.svg';
import goya from 'common/src/assets/image/agency/goya/logo.svg';

export function ProjectLogo(project) {
  const image = {
    instagram: insta,
    translations: trans,
    followers: follow,
    who: who,
    qr: qr,
    scanner: scanner,
    matdev: matdev,
    scannerapp: scannerapp,
    videoup: videoup,
    collage: collage,
    developerfaxer: developerfaxer,
    cleaner: cleaner,
    royalmobileapps: royalmobileapps,
    ereasy: ereasy,
    photoup: photoup,
    imote: imote,
    spamsafe: spamsafe,
    ai: ai,
    typi: typi,
    goya: goya,
  };
  return image[project];
}
